import { template as template_5dc1794d58e2499f93c814a3803e555b } from "@ember/template-compiler";
const FKText = template_5dc1794d58e2499f93c814a3803e555b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
