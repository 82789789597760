import { template as template_7d5e372e6e5846fea941cc22f16b93d1 } from "@ember/template-compiler";
const FKLabel = template_7d5e372e6e5846fea941cc22f16b93d1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
