import { template as template_9321ed52fe3447de97c415e78509b5b0 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_9321ed52fe3447de97c415e78509b5b0(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
