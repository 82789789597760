import { template as template_6c23799623ea40029628229368180430 } from "@ember/template-compiler";
const SidebarSectionMessage = template_6c23799623ea40029628229368180430(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
